<template>
  <v-app style="background: #E3F2FD; background: linear-gradient(to bottom, #1976D2, #1E88E5, #E3F2FD);">
    <!-- <v-content> -->
    <v-layout row wrap align-center justify-center>
      <v-flex xs6 md6 lg4 class="ma-5">
        <!-- <v-layout row wrap align-center justify-center>
                  <v-row justify="center">
                    <v-img :src="require('@/assets/img/logo_onebox-horizontal-png.png')" max-width="400" max-height="200" />
                  </v-row>
                </v-layout> -->
        <v-card class="mx-auto" outlined id="borderradius">
          <v-card-text class="pa-0">
            <v-layout row wrap align-center justify-center class="ma-0" v-if="this.dataAccountActive.type === 'Business'">
              <v-flex class="pa-4" v-if="status === 'Success'">
                <v-list two-line dense>
                  <v-list-item class="pl-0 pb-0">
                    <v-list-item-content>
                      <!-- <img src="@/assets/logo_onebox-horizontal-white.png" width="20%" height="20%" /> -->
                      <!-- <h1 style="color: #1976D2 ; " >ONE BOX</h1>
                        <h4 style="color: #1976D2; line-height: 40px;" >
                         Status payment
                        </h4>
                        <h1></h1> -->
                      <v-icon color="green" size="150px">mdi-checkbox-marked-circle</v-icon>
                      <h1 class="text-center">ชำระเงินเรียบร้อยแล้ว</h1>
                      <br />
                      <p class="text-center pa-2">"แพ็กเกจหลัก" ทางระบบจะปรับพื้นที่ให้ในรอบบิลถัดไป</p>
                      <p class="text-center">และ "แพ็กเกจเสริม" ทางระบบจะปรับพื้นที่ให้โดยมีผลทันที</p>
                      <v-divider></v-divider>
                      <v-layout>
                        <v-flex lg12 xs12 class="pt-4">
                          <h4 class="text-center pa-1">หมายเลขคำสั่งซื้อ : {{ orderId }}</h4>
                          <h4 class="text-center pa-1">วันที่สั่งซื้อ : {{ formatdatetime(paid_dtm) }}</h4>
                          <h4 class="text-center pa-1">วันที่รอบบิลถัดไป : {{ formatdatetime(paid_nextmonth) }}</h4>
                        </v-flex>
                      </v-layout>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <!-- <v-card-text>
                  <b>{{ $t("username") }}</b>
                  <v-text-field
                    class="mt-2"
                    outlined
                    solo
                    hide-details
                    flat
                    color="#174966"
                    prepend-inner-icon="account_box"
                    dense
                    @keyup.enter="login"
                    :disabled="loading"
                    v-model.trim="username"
                  ></v-text-field>
                  <br />
                  <b>{{ $t("password") }}</b>
                  <v-text-field
                    class="mt-2"
                    prepend-inner-icon="lock"
                    outlined
                    solo
                    color="#174966"
                    flat
                    dense
                    hide-details
                    :disabled="loading"
                    v-model.trim="password"
                    @keyup.enter="login"
                    @click:append="showpassword = !showpassword"
                    :type="showpassword ? 'text' : 'password'"
                    :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                  ></v-text-field>
                  <div class="pt-3 text-right">
                    <a
                      :href="urloneid + '/type_forgot_password'"
                      style="text-decoration: none;"
                      target="_blank"
                      >{{ $t("forgotpassword") }}</a
                    >
                  </div>
                </v-card-text>
                <v-card-text class="pt-0">
                  <v-btn
                    color="primary"
                    block
                    class="elevation-1 white--text"
                    @click="login"
                    :disabled="loading"
                    :loading="loading"
                  >
                    <b>{{ $t("login") }}</b>
                  </v-btn>
                </v-card-text> -->
                <!-- <v-card-text >
                  <b>{{ $t("username") }}</b>
                  <v-text-field
                    class="mt-2"
                    outlined
                    solo
                    hide-details
                    flat
                    color="#174966"
                    prepend-inner-icon="account_box"
                    dense
                    @keyup.enter="login"
                    :disabled="loading"
                    v-model.trim="username"
                  ></v-text-field>
                  <br />
                  <b>{{ $t("password") }}</b>
                  <v-text-field
                    class="mt-2"
                    prepend-inner-icon="lock"
                    outlined
                    solo
                    color="#174966"
                    flat
                    dense
                    hide-details
                    :disabled="loading"
                    v-model.trim="password"
                    @keyup.enter="login"
                    @click:append="showpassword = !showpassword"
                    :type="showpassword ? 'text' : 'password'"
                    :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                  ></v-text-field>
                  <div class="pt-3 text-right">
                    <a
                      :href="urloneid + '/type_forgot_password'"
                      style="text-decoration: none;"
                      target="_blank"
                      >{{ $t("forgotpassword") }}</a
                    >
                  </div>
                </v-card-text>
                <v-card-text class="pt-0 text-lg-center text-md-center text-sm-center" >
                  <v-btn
                    block
                    color="primary"
                    class="elevation-1 white--text"
                    @click="login"
                    :disabled="loading"
                    :loading="loading"
                  >
                    <b>{{ $t("login") }}</b>
                  </v-btn>
                </v-card-text>
                <v-card-text class="pt-0 text-lg-center text-md-center text-sm-center" >
                    <v-btn
                    block
                    color="primary"
                    outlined
                    class="elevation-1 white--text mr-1"
                    @click="goback"
                    :disabled="loading"
                    
                  >
                    <b>{{ $t("goback") }}</b>
                  </v-btn>
                </v-card-text> -->
                <br />
              </v-flex>
              <v-flex class="pa-4" v-else>
                <v-list two-line dense>
                  <v-list-item class="pl-0 pb-0">
                    <v-list-item-content>
                      <!-- <img src="@/assets/logo_onebox-horizontal-white.png" width="20%" height="20%" /> -->
                      <!-- <h1 style="color: #1976D2 ; " >ONE BOX</h1>
                        <h4 style="color: #1976D2; line-height: 40px;" >
                         Status payment
                        </h4>
                        <h1></h1> -->
                      <v-icon color="red" size="150px">mdi-alert</v-icon>
                      <h1 class="text-center">ไม่สำเร็จ</h1>
                      <br />
                      <p class="text-center pa-2">การชำระเงินไม่สำเร็จ กรุณาสอบตรวจสอบอีกครั้ง</p>
                      <v-divider></v-divider>
                      <v-layout>
                        <v-flex lg12 xs12 class="pt-4">
                          <h4 class="text-center pa-1">หมายเลขคำสั่งซื้อ : {{ orderId }}</h4>
                          <h4 class="text-center pa-1">วันที่สั่งซื้อ : {{ formatdatetime(paid_dtm) }}</h4>
                        </v-flex>
                      </v-layout>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <!-- <v-card-text>
                  <b>{{ $t("username") }}</b>
                  <v-text-field
                    class="mt-2"
                    outlined
                    solo
                    hide-details
                    flat
                    color="#174966"
                    prepend-inner-icon="account_box"
                    dense
                    @keyup.enter="login"
                    :disabled="loading"
                    v-model.trim="username"
                  ></v-text-field>
                  <br />
                  <b>{{ $t("password") }}</b>
                  <v-text-field
                    class="mt-2"
                    prepend-inner-icon="lock"
                    outlined
                    solo
                    color="#174966"
                    flat
                    dense
                    hide-details
                    :disabled="loading"
                    v-model.trim="password"
                    @keyup.enter="login"
                    @click:append="showpassword = !showpassword"
                    :type="showpassword ? 'text' : 'password'"
                    :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                  ></v-text-field>
                  <div class="pt-3 text-right">
                    <a
                      :href="urloneid + '/type_forgot_password'"
                      style="text-decoration: none;"
                      target="_blank"
                      >{{ $t("forgotpassword") }}</a
                    >
                  </div>
                </v-card-text>
                <v-card-text class="pt-0">
                  <v-btn
                    color="primary"
                    block
                    class="elevation-1 white--text"
                    @click="login"
                    :disabled="loading"
                    :loading="loading"
                  >
                    <b>{{ $t("login") }}</b>
                  </v-btn>
                </v-card-text> -->
                <!-- <v-card-text >
                  <b>{{ $t("username") }}</b>
                  <v-text-field
                    class="mt-2"
                    outlined
                    solo
                    hide-details
                    flat
                    color="#174966"
                    prepend-inner-icon="account_box"
                    dense
                    @keyup.enter="login"
                    :disabled="loading"
                    v-model.trim="username"
                  ></v-text-field>
                  <br />
                  <b>{{ $t("password") }}</b>
                  <v-text-field
                    class="mt-2"
                    prepend-inner-icon="lock"
                    outlined
                    solo
                    color="#174966"
                    flat
                    dense
                    hide-details
                    :disabled="loading"
                    v-model.trim="password"
                    @keyup.enter="login"
                    @click:append="showpassword = !showpassword"
                    :type="showpassword ? 'text' : 'password'"
                    :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                  ></v-text-field>
                  <div class="pt-3 text-right">
                    <a
                      :href="urloneid + '/type_forgot_password'"
                      style="text-decoration: none;"
                      target="_blank"
                      >{{ $t("forgotpassword") }}</a
                    >
                  </div>
                </v-card-text>
                <v-card-text class="pt-0 text-lg-center text-md-center text-sm-center" >
                  <v-btn
                    block
                    color="primary"
                    class="elevation-1 white--text"
                    @click="login"
                    :disabled="loading"
                    :loading="loading"
                  >
                    <b>{{ $t("login") }}</b>
                  </v-btn>
                </v-card-text>
                <v-card-text class="pt-0 text-lg-center text-md-center text-sm-center" >
                    <v-btn
                    block
                    color="primary"
                    outlined
                    class="elevation-1 white--text mr-1"
                    @click="goback"
                    :disabled="loading"
                    
                  >
                    <b>{{ $t("goback") }}</b>
                  </v-btn>
                </v-card-text> -->
                <br />
              </v-flex>
            </v-layout>
            <v-layout row wrap align-center justify-center class="ma-0" v-else>
               <v-flex class="pa-4" v-if="status === 'Success'">
                <v-list two-line dense>
                  <v-list-item class="pl-0 pb-0">
                    <v-list-item-content>
                      <v-icon color="green" size="150px">mdi-checkbox-marked-circle</v-icon>
                      <h1 class="text-center">ชำระเงินเรียบร้อยแล้ว</h1>
                      <br />
                      <p class="text-center pa-2">"ทางระบบดำเนินการปรับพื้นที่เรียบร้อยแล้ว</p>
                      <v-divider></v-divider>
                      <v-layout>
                        <v-flex lg12 xs12 class="pt-4">
                          <h4 class="text-center pa-1">หมายเลขคำสั่งซื้อ : {{ orderId }}</h4>
                          <h4 class="text-center pa-1">วันที่สั่งซื้อ : {{ formatdatetime(paid_dtm) }}</h4>
                          <h4 class="text-center pa-1">วันที่รอบบิลถัดไป : {{ formatdatetime(paid_nextmonth) }}</h4>
                        </v-flex>
                      </v-layout>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <br />
              </v-flex>
              <v-flex class="pa-4" v-else>
                <v-list two-line dense>
                  <v-list-item class="pl-0 pb-0">
                    <v-list-item-content>
                      <v-icon color="red" size="150px">mdi-alert</v-icon>
                      <h1 class="text-center">ไม่สำเร็จ</h1>
                      <br />
                      <p class="text-center pa-2">การชำระเงินไม่สำเร็จ กรุณาสอบตรวจสอบอีกครั้ง</p>
                      <v-divider></v-divider>
                      <v-layout>
                        <v-flex lg12 xs12 class="pt-4">
                          <h4 class="text-center pa-1">หมายเลขคำสั่งซื้อ : {{ orderId }}</h4>
                          <h4 class="text-center pa-1">วันที่สั่งซื้อ : {{ formatdatetime(paid_dtm) }}</h4>
                        </v-flex>
                      </v-layout>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <br />
              </v-flex>
            </v-layout>
             <v-layout row wrap justify-center>
          <v-flex lg12 xs12 class="ma-6 mr-4 ml-4 ">
            <div class="text-center">
              <v-btn class="ma-2" color="primary" dark @click="goback()">
                กลับสู่หน้าหลัก
              </v-btn>
              <v-btn class="ma-2" color="primary" outlined dark v-if="status === 'Success'&& fileid !== ''" @click="fn_download_taxinvoice()">
                ใบกำกับภาษี
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- </v-content> -->
    <dialogsnackbardowload
      :show="opensnackbardownloadfile"
      @closedialog="opensnackbardownloadfile = false"
      :filedata="file"
      :percen="newpercen"
      :name="namefile"
      :list="new_list"
      :btsdownload="btsdownload"
    ></dialogsnackbardowload>
  </v-app>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import VueCookies from "vue-cookies";
import { required, maxLength, email } from "vuelidate/lib/validators";
import Swal from "sweetalert2/dist/sweetalert2.js";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import gbfdecodearraybuffer from "@/globalFunctions/decodearraybuffer";
const dialogsnackbardowload = () => import("../components/optional/dialog-snackbardownload");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  data: function() {
    return {
      orderIdRef:"",
      fileid:"",
      orderId: "",
      paid_dtm: "",
      status: "",
      newlist: [],
      namefile: "",
      paid_nextmonth: "",
      file: {},
      percentCompleted: 0,
      newpercen: 0,
      new_list: [],
      btsdownload: false,
      listdatadowload: [],
      opensnackbardownloadfile: false,
    };
  },
  components: {
    dialogsnackbardowload,
  },
  computed: {
    ...mapState(["username", "authorize", "account_active","color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
       "dataStorageUsage",
      "dataStorageMax",
    ]),
  },
  methods: {
    formatdatetime(_datetime) {
      if (_datetime === "" || _datetime === null || _datetime === undefined) {
        return "-";
      } else {
        let dateyear = _datetime.split("")[0] + _datetime.split("")[1] + _datetime.split("")[2] + _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return dateday + "/" + datemonth + "/" + dateyear + " " + hour + ":" + minute + ":" + second;
      }
    },
    goback() {
      console.log("dierectory",this.dataAccountActive);
      console.log("dierectory",this.dataAccountActive.business_info.my_folder_id);
      // this.$router.push("/login");
      if(this.dataAccountActive.type === 'Citizen'){
       this.$router.push("/mydrive");
      }else{
        this.$router.push({ path: "/directory/" + this.dataAccountActive.business_info.my_folder_id });
      }
    },
    async fn_download_taxinvoice() {

      this.btsdownload = true;
      let datadowload = {};
      this.newlist = [];
      // this.listdatadowload.push(item);
      // for (let i = 0; i < this.listdatadowload.length; i++) {
      //   datadowload["fileid"] = this.listdatadowload[i].file_id;
      //   datadowload["name"] = this.listdatadowload[i].name_buyer_th;
      //   datadowload["value"] = 0;
      // }
      this.newlist.push({
         fileid: this.fileid,
          name: 'ใบกำกับภาษี_' + this.orderIdRef,
          value: 0,
      });
      console.log("this.newlist", this.newlist);
      // this.percentCompleted = 0;
      let url =
        process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
        "/api/v2/download_file?user_id=" +
        this.dataUsername +
        "&file_id=" +
        this.fileid;

      // for (let i = 0; i < this.listdatadowload.length; i++) {
      //   this.dataIndex = this.newlist.findIndex((obj) => obj.name === this.listdatadowload[i].name_buyer_th);
      // }
      // console.log("file", this.dataIndex);
      this.opensnackbardownloadfile = true;
      this.new_list = this.newlist;
      let auth = await gbfGenerate.generateToken();
      this.axios
        .get(url, {
          headers: { Authorization: auth.code },
          onDownloadProgress: (progressEvent) => {
            // console.log("progressEvent", progressEvent);
            let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log("progresspercent", progresspercent);
            // console.log(this.percentCompleted);

            if (this.newlist[0]["value"] === 95) {
              this.newlist[0]["value"] = 95;
            } else {
              this.newlist[0]["value"] = progresspercent;
            }
            // console.log("percenfile", this.newlist[this.dataIndex]["value"]);
            this.newpercen = this.newlist[0]["value"];
            this.namefile = this.newlist[0]["name"];
          },
          withCredentials: false,
          responseType: "arraybuffer",
        })
        .then((response) => {
          // ตรวจสอบว่า  API ทำงานถูกต้อง
          if (response.statusText === "OK") {
            console.log("response++++++++++++ OK", response);
            // ตรวจสอบว่า Backend ส่ง Error มาหรือไม่
            if (
              parseInt(response.headers["content-length"]) < 700 &&
              parseInt(response.headers["content-type"]) === "application/json"
            ) {
              // Backend ไม่ส่งไฟล์มา
              console.log("response < 1000", response);
              this.opensnackbardownloadfile = false;
              let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
              console.log("responseerror", res_error);
              Toast.fire({
                icon: "error",
                title: res_error.errorMessage,
              });
            } else {
              this.opensnackbardownloadfile = true;
              this.newlist[0]["value"] = 100;
              this.btsdownload = false;

              let headers = response.headers;
              let blob = new Blob([response.data], { type: headers["content-type"] });
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = 'ใบกำกับภาษี_' + this.orderIdRef;
              link.click();
              setTimeout(() => {
                this.opensnackbardownloadfile = false;
              }, 2500);
            }
          }
          this.listdatadowload = []; //เคลียร์listที่เลือกดาวน์โหลด
          this.newlist = []; //เคลียร์ array เป็น key แล้ว
          this.newpercen = []; //เคลียร์ค่าเปอร์เซ็น
        })
        .catch((error) => {
          this.btsdownload = false;
          // this.opensnackbar = false;
          this.opensnackbardownloadfile = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลดนี้ได้",
          });
          console.log("err", error);
        });
    },
  },
  mounted() {
    console.log(this.$route.query);
    this.orderId = this.$route.query.orderId;
    this.paid_dtm = this.$route.query.paid_dtm;
    this.status = this.$route.query.status_payment;
    this.fileid = this.$route.query.file_id
    this.orderIdRef = this.$route.query.orderIdRef
    this.paid_nextmonth = this.$route.query.paid_nextmonth
  },
};
</script>
<style>
#borderradius {
  border-radius: 20px;
}
</style>
